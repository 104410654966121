import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import ThemeInput from "../components/ThemeInput";
import { useForm } from "react-hook-form";
import { validation, validationText } from "../utils/constants";
import { BiPhoneCall } from "react-icons/bi";
import { FaFax } from "react-icons/fa6";
import { HiOutlineMailOpen } from "react-icons/hi";
import { success } from "../utils/helper";
import ThemeSpinner from "../components/ThemeSpinner";


const ContactUs = () => {
    const [selected, setSelected] = useState(null)
    const [loading, setLoading] = useState(false)
    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627,
        },
        zoom: 11,
    };
    const AnyReactComponent = ({ text }) => <div>{text}</div>;

    const find = [
        { key: 1, value: "Insta" },
        { key: 2, value: "Facebook" },
        { key: 3, value: "Tweeter" },
    ]

    const onSubmit = (data) => {
        setLoading(true);
        console.log(data);
        setTimeout(() => {
            success("Thank you for contacting with us we will get back to you soon!");
            setLoading(false);
            reset();
        }, 500);
    }
    return (
        <div className="contact-us container d-flex justify-content-center align-items-center flex-column text-center mt-5 mb-5">
            <div className="text mb-5 d-flex justify-content-center align-items-center flex-column text-center">
                <h1>Get In touch with us</h1>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
            </div>
            <div className="box d-flex justify-content-center align-items-center">
                <div className="get-in-touch d-flex">
                    <div className="form-content w-100">
                        <div className="top-content mb-3">
                            <h1>Get in <span>Touch</span></h1>
                            <p>Enim tempor eget pharetra facilisis sed maecenas adipiscing. Eu leo molestie vel, ornare non id blandit netus.</p>
                        </div>
                        <div className="form-contact-us w-100">
                            <Form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
                                <ThemeInput
                                    name="name"
                                    errors={errors}
                                    control={control}
                                    type={"text"}
                                    className={""}
                                    placeholder={"Name"}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: validationText.nameRequired,
                                        },
                                        maxLength: {
                                            value: validation.nameMax,
                                            message: validationText.nameMax,
                                        },
                                    }}
                                />
                                <ThemeInput
                                    name="email"
                                    errors={errors}
                                    control={control}
                                    type={"email"}
                                    className={"mt-3"}
                                    placeholder={"Email Address"}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: validationText.emailRequired,
                                        },
                                        maxLength: {
                                            value: validation.emailMax,
                                            message: validationText.emailMax,
                                        },
                                    }}
                                />
                                <ThemeInput
                                    name="phonenumber"
                                    errors={errors}
                                    control={control}
                                    type={"number"}
                                    className={"mt-3"}
                                    placeholder={"Phone number"}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: validationText.general,
                                        },
                                        maxLength: {
                                            value: validation.phoneNumber,
                                            message: validationText.phoneNumberMax,
                                        },
                                    }}
                                />
                                <select
                                    className="find-dropdown mt-3"
                                    onChange={(e) => setSelected(e.target.value)}
                                    value={selected}
                                >
                                    <option className={"disabled-option"} value="" disabled selected hidden>
                                        How did you find us?
                                    </option>
                                    {find?.map((dt) => (
                                        <option className={"options"} key={dt?.key} value={dt?.key}>
                                            {dt?.value}
                                        </option>
                                    ))}
                                </select>
                                <Button type="submit" className="theme-btn mt-4">{loading ? <ThemeSpinner /> : "Send"}</Button>
                            </Form>
                            <div className="contacts mt-4 d-flex justify-content-between align-itms-center">
                                <div className="d-flex data align-items-center">
                                    <BiPhoneCall size={28} color="#B1B5B6" />
                                    <div className="text ms-3 d-flex flex-column">
                                        <p>Phone</p>
                                        <span>03 5432 1234</span>
                                    </div>
                                </div>
                                <div className="d-flex data align-items-center">
                                    <FaFax size={28} color="#B1B5B6" />
                                    <div className="text ms-3 d-flex flex-column">
                                        <p>Fax</p>
                                        <span>03 5432 1234</span>
                                    </div>
                                </div>
                                <div className="d-flex data align-items-center">
                                    <HiOutlineMailOpen size={28} color="#B1B5B6" />
                                    <div className="text ms-3 d-flex flex-column">
                                        <p>Email</p>
                                        <span>info@theway.com.au</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="location">
                        <div className={"map"} style={{ height: "100%", width: "405px" }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: "AIzaSyAEl91Vndp067MG7IhjWYgjrPF5IMW4_88" }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                            >
                                <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
                            </GoogleMapReact>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactUs