import React from "react";
import { Col, Row } from "react-bootstrap";

import easywaymobile from "../../assets/images/easywaymobile.png"
import AppleStore from "../../assets/images/apple_store.png"
import GoogleStore from "../../assets/images/google_store.png"

const EasyWay = () => {
    return (
        <div className="your-one-stop-component container mt-5 mb-5">
            <Row>
                <Col lg={6}>
                    <div className="content your-one-stop-component-md">
                        <div className="text">
                            <h2>Easy Way To</h2>
                            <span>Check Your History</span>
                        </div>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic                        </p>
                        <div className=" store_image d-flex mt-5">
                            <img loading="lazy" src={AppleStore} alt="" />
                            <img loading="lazy" src={GoogleStore} alt="" />
                        </div>
                    </div>
                </Col>
                <Col lg={6} className="image-col">
                    <div className="image">
                        <img src={easywaymobile} className="image-fluid object-fit-contain" alt="your-one-stop-image" />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default EasyWay;