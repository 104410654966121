import React from "react";
import { Col, Row } from "react-bootstrap";

import youronestop from "../../assets/images/youronestop.png"
import path from "../../assets/images/Path.png"
import AppleStore from "../../assets/images/apple_store.png"
import GoogleStore from "../../assets/images/google_store.png"

const YourOneStop = () => {
    return (
        <div className="your-one-stop-component container mt-5">
            <Row>
                <Col lg={6}>
                    <div className="image-content d-flex">
                        <div className="image">
                            <img src={youronestop} className="image-fluid object-fit-contain" alt="your-one-stop-image" />
                        </div>
                        <div className="map-img">
                            <img src={path} className="image-fluid object-fit-contain" alt="your-one-stop-image" />
                        </div>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="content">
                        <div className="text">
                            <h2>Your One-Stop</h2>
                            <span>Solution for Bookings</span>
                        </div>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic                        </p>
                        <div className=" store_image d-flex mt-5">
                            <img loading="lazy" src={AppleStore} alt="" />
                            <img loading="lazy" src={GoogleStore} alt="" />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default YourOneStop;