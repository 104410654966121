import React from "react";

import TopNavBanner from "../components/TopNavaBar";
import AddBannar from "../components/home/AddBannar";
import ApplicationFeature from "../components/home/ApplicationFeature";
import YourOneStop from "../components/home/YourOneStop";
import EasyWay from "../components/home/EasyWay";
import HomeSlider from "../components/home/TheWayAppSlider";
import ThemeFooter from "../components/ThemeFooter";

const Home = () => {
    return (
        <div className="home">
            <div className="top-bannar mb-5">
                <TopNavBanner className={"mt-5 home-add-nav"} />
                <AddBannar className={"mb-5"} />
            </div>
            <div className="application-feature">
                <ApplicationFeature />
            </div>
            <div className="your-one-stop">
                <YourOneStop />
            </div>
            <div className="easy-way">
                <EasyWay />
            </div>
            <div className="app-slider">
                <HomeSlider />
            </div>
            <ThemeFooter />
        </div>
    )
}

export default Home;