import React from "react";
import { Col, Row } from "react-bootstrap";
import MobileScreen from "../../assets/images/mobile_screen_feature.png";
import { CubeIcon, StarIcon, TrangleIcon } from "../../utils/svgicons";

const records = [
    {
        title: "budgeting intervals",
        des: "Cum et convallis risus placerat aliquam, nunc. Scelerisque aliquet faucibus tincidunt eu adipiscing sociis arcu lorem porttitor.",
        icon: <StarIcon />,
    },
    {
        title: "budgeting intervals",
        des: "Cum et convallis risus placerat aliquam, nunc. Scelerisque aliquet faucibus tincidunt eu adipiscing sociis arcu lorem porttitor.",
        icon: <CubeIcon />,
    },
    {
        title: "budgeting intervals",
        des: "Cum et convallis risus placerat aliquam, nunc. Scelerisque aliquet faucibus tincidunt eu adipiscing sociis arcu lorem porttitor.",
        icon: <TrangleIcon />,
    },
];

const ApplicationFeature = () => {
    return (
        <div className="application_feature_component container mt-5">
            <Row>
                <Col xs={12}>
                    <div className="headers">
                        <h1> Application <span>Features</span></h1>
                    </div>
                </Col>
            </Row>

            <Row className="mt-4 mobile_section">
                <Col xs={12} sm={6} md={6} lg={4} className="description">
                    {records.map((v, index) => (
                        <div key={index} className="d-flex gap-4">
                            <div className="d-flex flex-column gap-2">
                                <h4 className="text-end">{v.title}</h4>
                                <div className="text-end details">{v.des}</div>
                            </div>
                            <div>{v.icon}</div>
                        </div>
                    ))}
                </Col>

                <Col lg={4} className="d-lg-block d-none">
                    <div className="d-flex align-items-center justify-content-center image_section">
                        <img loading="lazy" src={MobileScreen} alt="" />
                    </div>
                </Col>

                <Col xs={12} sm={6} md={6} lg={4} className="description description-md">
                    {records.map((v, index) => (
                        <div key={index} className="d-flex gap-4">
                            <div>{v.icon}</div>

                            <div className="d-flex flex-column gap-2">
                                <h4 className="text-start">{v.title}</h4>
                                <div className="text-start details">{v.des}</div>
                            </div>
                        </div>
                    ))}
                </Col>
            </Row>
        </div>
    );
};

export default ApplicationFeature;
