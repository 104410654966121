import React from "react";

import AppleStore from "../assets/images/apple_store.png"
import GoogleStore from "../assets/images/google_store.png"
import ApplicationFeature from "../components/home/ApplicationFeature";
import { Col, Row } from "react-bootstrap";
import userfriendly from "../assets/images/userfriendly.png"
import yourbooking from "../assets/images/yourbooking.png"
import HomeSlider from "../components/home/TheWayAppSlider";

const Features = () => {
    return (
        <div className="features">
            <div className="content container">
                <div className="discover mt-5 mb-5 d-flex flex-column justify-content-center align-items-center w-100">
                    <h1>Discover What <span>Makes Us Unique</span></h1>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                    <div className="store_image d-flex mt-5">
                        <img loading="lazy" src={AppleStore} alt="apple" />
                        <img loading="lazy" src={GoogleStore} alt="google" />
                    </div>
                </div>
                <div className="application-feature mt-10">
                    <ApplicationFeature />
                </div>
                <div className="inovation discover mt-10 mb-5 d-flex flex-column justify-content-center align-items-center w-100">
                    <h1>Innovative Tools for <span>Effortless Travel</span></h1>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequat</p>
                </div>
                <div className="user-friendly mt-5">
                    <Row>
                        <Col lg={6}>
                            <div className="content">
                                <div className="text">
                                    <h1>User-Friendly <span>Interface</span></h1>
                                </div>
                                <p>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                            </div>
                        </Col>
                        <Col lg={6} className="image-col d-flex justify-content-center">
                            <div className="image">
                                <img src={userfriendly} className="image-fluid object-fit-contain" alt="your-one-stop-image" />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="your-booking mt-5">
                    <Row>
                        <Col lg={6} className="image-col d-flex justify-content-center">
                            <div className="image">
                                <img src={yourbooking} className="image-fluid object-fit-contain" alt="your-one-stop-image" />
                            </div>
                        </Col>
                        <Col lg={6} className="md-space-margin">
                            <div className="content">
                                <div className="text">
                                    <h1>Your Booking</h1>
                                    <span>History at a Glance</span>
                                </div>
                                <p>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="app-slider mt-5 mb-5">
                <HomeSlider />
            </div>
        </div>
    )
}

export default Features;