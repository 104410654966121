import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom/dist";
import Logo from "../assets/images/Logo.svg";

const TopNavBanner = ({ className }) => {
    let location = window.location;
    let pathname = location.pathname;

    const navigate = useNavigate();

    const activeLink = (route) => {
        return pathname === route ? true : false;
    };

    const [expanded, setExpanded] = useState(false);

    const handleNavToggle = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    const navLinks = [
        { id: 1, title: "Home", to: "/" },
        { id: 2, title: "About Us", to: "/about-us" },
        { id: 3, title: "Features", to: "/features" },
        { id: 4, title: "Terms", to: "/terms-and-condition" },
        { id: 5, title: "Privacy", to: "/privacy-policy" }
    ]
    return (
        <>
            <div className={`top-nav-bar ${className} container`}>
                <Navbar bg="" expand="lg" variant="dark">
                    <Navbar.Brand href="/">
                        <img src={Logo} alt="produtc-logo" className="object-fit-contain image" />
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        onClick={handleNavToggle}
                        className="ms-auto"
                    />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center align-items-center">
                        <Nav
                            className=""
                            defaultActiveKey="/"
                            onClick={handleNavToggle}
                        >
                            {navLinks?.map((dt => (
                                <Nav.Item className={"p-3"} key={dt?.id}>
                                    <Link
                                        to={dt?.to}
                                        onClick={handleNavToggle}
                                        className={activeLink(dt?.to) ? "active" : ""}
                                    >
                                        {dt?.title}
                                    </Link>
                                </Nav.Item>
                            )))}
                            <Button
                                onClick={() => navigate(`/contact-us`)}
                                className="btn-theme-default btn-contact-us d-block d-lg-none"
                            >
                                Contact Us
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                    <Nav>
                        <Button
                            onClick={() => navigate(`/contact-us`)}
                            className="btn-theme-default btn-contact-us d-lg-block d-none mb-2"
                        >
                            Contact Us
                        </Button>
                    </Nav>

                </Navbar>
            </div>
        </>
    );
};

export default TopNavBanner;
