import React from "react";
import { Button, Col, Row } from "react-bootstrap";

import rectangle from "../../assets/images/rectangle.png"; // Blue background image
import mobileadd from "../../assets/images/mobileadd.svg"; // Mobile image
import mdbannar from "../../assets/images/md-bannar.svg";

const AddBannar = ({ className }) => {
    return (
        <div className="add-bannar">
            <div className={`add-bannar-content container ${className}`}>
                <Row>
                    {/* Left Content Section */}
                    <Col md={12} lg={5} className="content-column">
                        <div className="content d-flex flex-column">
                            <h1>
                                Your Travel Needs in One Place The Way to Book{" "}
                                <span>Cars, Flights, and Hotels</span>
                            </h1>
                            <p>
                                You can manage your finances very easily and quickly with this platform.
                                You can focus on results faster. You can manage your finances very easily
                            </p>
                            <div className="d-flex buttons">
                                <Button className="download-blue">Download</Button>
                                <Button className="download-white">Download</Button>
                            </div>
                        </div>
                    </Col>

                    {/* Right Image Section */}
                    <Col lg={7} className="add-image-column d-lg-block d-none">
                        <div className="mobile-add">
                            <img className="blue-bg-img" src={rectangle} alt="blue-bg" />
                        </div>
                    </Col>
                    <Col md={12} className="add-image-column-md d-lg-none ">
                        <div className="mobile-add-image">
                            <img src={mdbannar} alt="blue-bg" />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="mobile-image d-lg-block d-none">
                <img src={mobileadd} alt="mobile-add-image" />
            </div>
        </div>

    );
};

export default AddBannar;
