import React, { useEffect } from 'react';

import Footerlogo from "../assets/images/footerlogo.png"
import { Link, useLocation } from 'react-router-dom';

const ThemeFooter = () => {
    let location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location.hash]);

    useEffect(() => {
        let path = location.pathname.split("");

        if (path?.length > 1) {
            window.scrollTo(0, 0);
        }
    }, [location]);


    const navLinks = [
        { id: 1, title: "Home", to: "/" },
        { id: 2, title: "About Us", to: "/about-us" },
        { id: 3, title: "Features", to: "/features" },
        { id: 4, title: "Terms", to: "/terms-and-condition" },
        { id: 5, title: "Privacy", to: "/privacy-policy" }
    ]
    
    return (
        <div className='theme-footer container mb-4'>
            <div className='content d-flex align-items-center justify-content-between'>
                <img src={Footerlogo} alt='footer-logo' className='object-fit-contain image-fluid' />
                <div className='list d-flex'>
                    {navLinks?.map((dt => (
                        <div className={"p-3 text"} key={dt?.id}>
                            <Link
                                to={dt?.to}
                            >
                                {dt?.title}
                            </Link>
                        </div>
                    )))}
                </div>
                <div className='email'>
                    <p>theway@gmail.com</p>
                </div>
            </div>
        </div>
    )
}

export default ThemeFooter;