export const baseUrl = "https://jsonplaceholder.typicode.com";

export const constants = {
  postlimit: 4
}
export const validation = {
  emailMax: 322,
  passwordMin: 5,
  passwordMax: 32,
  nameMin: 5,
  nameMax: 60,
  descriptionMax: 255,
  text: 120,
  amount: 10,
  phoneNumber: 15,
  message: 255,
};

export const validationText = {
  general: "Required",
  phoneNumberMax: `Max Length is ${validation.phoneNumber}`,
  passwordMatch: "Password did not match",
  emailRequired: "Email is required",
  titleRequired: "Title is required",
  nameemailMax: `Max length is ${validation.emailMax}`,
  invalidEmail: "Invalid email pattern",
  amountMax: `Max length is ${validation.amount}`,
  currentPasswordRequired: "Current password is required",
  newPasswordRequired: "New Password is required",
  confirmNewPasswordRequired: "Confirm new password is required",
  passwordRequired: "Password is required",
  passwordMin: `Max length is ${validation.passwordMin}`,
  passwordMax: `Max length is ${validation.passwordMax}`,

  nameRequired: "Name is required",
  nameMin: `Max length is ${validation.nameMin}`,
  nameMax: `Max length is ${validation.nameMax}`,

  descriptionRequired: "Description is required",
  descriptionMax: `Max length is ${validation.descriptionMax}`,

  numberAllowed: "Only numbers are allowed",
  numberAndDecimalAllowed: "Only numbers and decimal are allowed",

  questionRequired: "Question is required",
  answerRequired: "Answer is required",
  urlRequired: "Url is required",
  urlInvalid: 'Please enter a valid URL'
};

export const localStorageConstant = {
  tokenKey: "project_name_token",
  userKey: "project_name_user",
};

// export const firebaseConfig = {
//   apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
//   authDomain: import.meta.env.VITE_FRIREBASE_AUTH_DOMAIN,
//   projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
//   storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
//   messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
//   appId: import.meta.env.VITE_FIREBASE_APP_ID,
// };

export const modalType = {
  view: "view",
  edit: "edit",
  delete: "delete",
  block: "block",
};

export const apiUrls = {
  signin: "/admin/sign-in",
  signout: "/admin/signout",
  changePassword: "/admin/change-password",
  tcpp: "/admin/TcPp",
  getTcpp: "/admin/getTcPp",
  getAllUsers: "/admin/get-all-users",
  blockUnblockUser: "/admin/blockunblock",
  deleteUser: "/admin/delete-account",
  dashboardData: "/admin/dashboard",
  graph: "/admin/graph",
  sendPush: "/admin/send-push",
  faq: "/admin/faq",
  reportTypes: "/admin/report-types",
};

export const Role = {
  company: 2,
  user: 1,
};

export const BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 991,
  DESKTOP: 1200,
};

export const IMAGE_TYPES = "*.png,*jpeg,*.jpg,image/*";

export const ADD_MEDIA_TYPES = "*.png,*jpeg,*.jpg,*.mp4,image/*,video/*";


