import { toast } from "react-toastify";

export const success = (msg) =>
    toast.success(msg, {
        hideProgressBar: true,
        autoClose: 1500,
        position: "top-right",
    });

export const error = (msg) =>
    toast.error(msg, {
        hideProgressBar: true,
        autoClose: 1500,
        position: "top-right",
    });

export const warning = (msg) =>
    toast.warning(msg, {
        hideProgressBar: true,
        autoClose: 1500,
        position: "top-right",
    });
