import React from "react";
import TopNavBanner from "../components/TopNavaBar";
import ThemeFooter from "../components/ThemeFooter";
const ThemeLayout = ({ children }) => {
    return (
        <div className="theme-layout">
            <TopNavBanner className={"mt-5"}/>
            <div className="children">
                {children}
            </div>
            <ThemeFooter />
        </div>
    )
}

export default ThemeLayout;