import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import one from "../../assets/images/1.png";
import two from "../../assets/images/2.png";

const HomeSlider = () => {
    const data = [
        { id: 1, image: one },
        { id: 2, image: two },
        { id: 3, image: one },
        { id: 4, image: two },
        { id: 5, image: one },
        { id: 6, image: one },
        { id: 7, image: two },
        { id: 8, image: one },
        { id: 9, image: two },
        { id: 10, image: one },
    ];
    return (
        <div className="home-slider-componenet mt-5 mb-5">
            <div className="top-text mb-3">
                <h1>
                    THE WAY <span>APPLICATION TOUR</span>
                </h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
            </div>
            <div className="slider-component">
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 2.5,
                    }}
                    pagination={{ el: ".swiper-pagination", clickable: true }}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                        clickable: true,
                    }}
                    modules={[EffectCoverflow, Navigation]}
                    className="swiper_container"
                >
                    {data?.map((dt) => (
                        <>
                            <SwiperSlide key={dt?.id}>
                                <img loading="lazy" src={dt?.image} alt="slide_image" />
                            </SwiperSlide>
                        </>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default HomeSlider;
