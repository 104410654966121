import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import ThemeLayout from "../layouts/ThemeLayout";
import AboutUs from "../pages/AboutUs";
import Features from "../pages/Features";
import ContactUs from "../pages/ContactUs";
import TermsAndConditions from "../pages/Terms";
import Privacy from "../pages/Privacy";

const Navigation = () => {
    return (
        <>
            <Routes>
                <Route path={"/"} element={<Home />}></Route>
                <Route path={"/about-us"} element={<ThemeLayout children={<AboutUs />} />}></Route>
                <Route path={"/features"} element={<ThemeLayout children={<Features />} />}></Route>
                <Route path={"/contact-us"} element={<ThemeLayout children={<ContactUs />} />}></Route>
                <Route path={"/terms-and-condition"} element={<ThemeLayout children={<TermsAndConditions />} />}></Route>
                <Route path={"/privacy-policy"} element={<ThemeLayout children={<Privacy />} />}></Route>
            </Routes>
        </>
    )
}

export default Navigation